import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/App.css';
import './css/responsive.css';

import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';

import Home from './components/Home.jsx';
import About from './components/About.jsx';
import ApplicationDev from './components/Application-Dev.jsx';
import CloudSecurity from './components/services/CloudSecurity.jsx';
import CloudConsulting from './components/services/CloudConsulting.jsx';
import CloudArchitecture from './components/services/CloudArchitecture.jsx';
import CloudNative from './components/services/CloudNative.jsx';
import DevOpsAutomation from './components/services/DevOpsAutomation.jsx';
import SystemIntegration from './components/System-Integration.jsx';
import Contact from './components/Contact.jsx';


function App() {
  return (
    <Router>
      <div className="boxed_wrapper">
        <header className="top-bar">
          <div class="container">
            <div class="clearfix">
              <ul class="top-bar-text float_left">
                <li className="icon-list-item"><PhoneInTalkOutlinedIcon /> Phone +230 5 840 1645</li>
                <li className="icon-list-item"><MarkEmailUnreadOutlinedIcon />  roshan@cloudzen.digital</li>
                <li className="icon-list-item"><BusinessOutlinedIcon />  22 Bis Dr Hassen Sakir Street, Port Louis, Mauritius</li>
              </ul>
            </div>
          </div>
        </header>

        <Navbar className="custom-navbar" expand="lg">
          <Container>
            {/* Logo */}
            <Navbar.Brand href="/">
              <img
                src="/images/cloudzen_digital_logo.jpeg"
                width="120"
                height="120"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link as={Link} to="/" className="nav-link-custom">Home</Nav.Link>
                <Nav.Link as={Link} to="/about" className="nav-link-custom">About Us</Nav.Link>
                {/* Sub-menu */}
                <NavDropdown title="Services" id="basic-nav-dropdown" className="nav-link-custom">
                  <NavDropdown.Item href="/application-dev">Applications Development</NavDropdown.Item>
                  <NavDropdown.Item href="/system-integration">Systems Integration</NavDropdown.Item>
                  <NavDropdown.Item href="/devops-automation">DevOps & Automations</NavDropdown.Item>
                  <NavDropdown.Item href="/cloud-security">Cloud Security</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="/cloud-consulting">Cloud Consulting</NavDropdown.Item>
                  <NavDropdown.Item href="/cloud-architecture">Cloud Architecture & Design</NavDropdown.Item>
                  <NavDropdown.Item href="/cloud-native">Cloud Native Applications</NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="Solutions" id="basic-nav-dropdown" className="nav-link-custom">
                  <NavDropdown.Item href="/enterprise-apps">Enterprise Applications</NavDropdown.Item>
                  <NavDropdown.Item href="/big-data">Big Data Analytics</NavDropdown.Item>
                  <NavDropdown.Item href="/mobile-apps">Mobile Applications</NavDropdown.Item>
                  <NavDropdown.Item href="/storage">Storage Technologies</NavDropdown.Item>
                  <NavDropdown.Item href="/testing-apps">Application Testing</NavDropdown.Item>
                </NavDropdown>
                <Nav.Link as={Link} to="/contact" className="nav-link-custom">Contact</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/application-dev" element={<ApplicationDev />} />
            <Route path="/system-integration" element={<SystemIntegration />} />
            <Route path="/devops-automation" element={<DevOpsAutomation />} />

            <Route path="/cloud-consulting" element={<CloudConsulting />} />
            <Route path="/cloud-architecture" element={<CloudArchitecture />} />
            <Route path="/cloud-native" element={<CloudNative />} />
            <Route path="/cloud-security" element={<CloudSecurity />} />
          </Routes>
          
          <footer>
              <p>&copy; 2024 CloudZen Solutions - Mauritius</p>
          </footer>
        
      </div>
    </Router>
  );
}


export default App;