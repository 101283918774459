import React from 'react';
import { Link } from 'react-router-dom';

import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';


function Contact() {
  const divStyle = {
    backgroundImage: "url('/images/background/contact.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div className="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div className="container">
            <div className="box">
                <h3>Contact Us</h3>
            </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Contact Us</li>
          </ol>
        </div>
      </nav>

      <section class="contact_details sec-padd">
        <div class="container">
          <div class="section-title">
              <h3>contact details</h3>
          </div>
          <div class="text">
              <p>If any details you have to require, please find below contact details and reach us today at our Location!</p>
          </div>
          <div class="row">
            <div class="col-md-4 col-sm-8 col-xs-12">
              <div class="default-cinfo">
                <div class="accordion-box">
                  <div class="accordion animated out" data-delay="0" data-animation="fadeInUp">
                    <div class="acc-btn active">
                      CloudZen Solutions
                      <div class="toggle-icon">
                        <i class="plus fa fa-angle-right"></i><i class="minus fa fa-angle-down"></i>
                      </div>
                    </div>
                    <div class="acc-content collapsed">
                      <ul class="contact-infos">
                        <li>
                          <div class="icon_box">
                            <BusinessOutlinedIcon />
                          </div>
                          <div class="text-box">
                            <p><b>Address:</b></p> 
                            <p>22 Bis Dr Hassen Sakir Street, Port Louis, Mauritius</p>
                          </div>
                        </li>
                        <li>
                          <div class="icon_box">
                            <PhoneInTalkOutlinedIcon />
                          </div>
                          <div class="text-box">
                            <p><b>Call Us:</b></p> 
                            <p>+230 5 840 1645</p>
                          </div>
                        </li>
                        <li>
                          <div class="icon_box">
                            <MarkEmailUnreadOutlinedIcon />
                          </div>
                          <div class="text-box">
                            <p><b>Mail Us:</b></p> 
                            <p>roshan@cloudzen.digital</p>
                          </div>
                        </li>
                        <li>
                          <div class="icon_box">
                            <ScheduleIcon />
                          </div>
                          <div class="text-box">
                            <p><b>Opening Time:</b></p>
                            <p>Mon - Sat: 05:00 (GMT) to 14:00 (GMT)</p>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 col-sm-12 col-xs-12">
              <div class="home-google-map">   
                <iframe title="google map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.3838122397556!2d57.51153117623991!3d-20.159738781281032!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c5103e3fa640d%3A0x40e9a81eaabef5bf!2s22%20Dr%20Hassen%20Sakir%20St%20(ex%20Pagoda)%2C%20Port%20Louis!5e0!3m2!1sen!2smu!4v1728910724835!5m2!1sen!2smu" width="600" height="450"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;