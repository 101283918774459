import React from 'react';
import { Link } from 'react-router-dom';

function SystemIntegration() {
  const divStyle = {
    backgroundImage: "url('/images/background/cloud-software.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
              <h3>Systems Integration</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Systems Integration</li>
          </ol>
        </div>
      </nav>
    </div>
  );
}

export default SystemIntegration;