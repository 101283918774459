import React from 'react';
import { Link } from 'react-router-dom';

function CloudSecurity() {
  const divStyle = {
    backgroundImage: "url('/images/background/cloud-software.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
            <h3>Cloud Services</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Cloud Security</li>
          </ol>
        </div>
      </nav>

      <section className="content-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="about-info">
                <h4>Security Engineering</h4>
                <div class="text">
                  <p>CloudZen Solutions leverages security practices amongst top cloud service providers, such as Amazon Web Services (AWS), Microsoft Azure and Google Cloud Platform (GCP).</p>
                  
                  <p class="pt-20"> <strong>Unburden the customers</strong></p>
                  <p>A shared responsibility model between the cloud security engineers and ourself, whether working individually or as part of a team, to continuously adhere to security best practices and conduct regular reviews to safeguard cloud configurations and applications from potential cloud threats.</p>

                  <p class="pt-20"><strong>Proactive approach at your fingertips</strong></p>
                  <p>At CloudZen Solutions, we implement a proactive approach that strengthens your organization’s security posture in the cloud environment. Regular security audits, threat assessments, and keeping up with the latest security trends and updates are crucial for maintaining robust cloud security.</p>
                </div>

                <div class="link_btn">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <img src='/images/service5.avif' alt=""/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CloudSecurity;