import React from 'react';
import { Link } from 'react-router-dom';

function CloudConsulting() {
  const divStyle = {
    backgroundImage: "url('/images/background/services.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
            <h3>Service Offerings</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Cloud Consulting</li>
          </ol>
        </div>
      </nav>

      <section className="content-section-full">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h3>Cloud Strategy & Consulting</h3>
              <img src='/images/OIP-4.jpeg' alt="" 
                style={{
                    width: '500px',
                    height: 'auto',
                    float: 'right',
                    marginRight: '10px'}}/>
              <br/>
              <div className='text'>
                <p>At CloudZen, we work with you to design and implement a cloud strategy that aligns with your business objectives. Whether you’re migrating to the cloud or optimizing your current infrastructure, we ensure a seamless transition.</p>
                <br/>
                
                <p class="pt-20"> <strong>Cloud assessment & roadmap development</strong></p>
                <p>We start by thoroughly understanding your business needs, technical landscape, and future goals. Our Cloud Assessment services evaluate your existing infrastructure, application architecture, and operational processes to identify opportunities for improvement, cost savings, and scalability in the cloud.</p>
                <br/>
                <p>Our Cloud Assessment process includes:</p>
                <ul>
                  <li><b>•	Current Infrastructure Review:</b> We analyze your on-premises or existing cloud infrastructure, assessing hardware, software, networking, and security protocols.</li>
                  <li><b>•	Application & Workload Evaluation:</b> We identify how your applications and workloads perform, highlighting areas where performance, scalability, or cost-efficiency can be optimized in a cloud environment.</li>
                  <li><b>•	Security & Compliance Review:</b> Our team ensures that your infrastructure and applications adhere to industry best practices for security, identifying any gaps in compliance, such as GDPR, HIPAA, or SOC 2.</li>
                  <li><b>•	Cost Analysis & Optimization:</b> We evaluate your current IT expenditures and develop strategies for reducing costs through cloud migration, resource optimization, and leveraging the right cloud pricing models.</li>
                </ul>
                <br/>
                <p>After the comprehensive assessment, we move on to Roadmap Development, creating a strategic, phased approach for your cloud journey. Whether you are just beginning with cloud adoption or optimizing a mature cloud environment, our roadmap ensures smooth and efficient transitions.</p>
                <br/>
                <p>Key elements of Roadmap Development include:</p>
                <ul>
                  <li><b>•	Cloud Strategy Alignment:</b> We align your cloud strategy with your business goals, ensuring that the roadmap supports long-term objectives like scalability, innovation, or cost-efficiency.</li>
                  <li><b>•	Migration Planning:</b> For businesses moving to the cloud, we plan a seamless migration, detailing timelines, priorities, and steps to avoid disruptions. This includes data migration strategies, application refactoring (if needed), and risk mitigation plans.</li>
                  <li><b>•	Cloud Platform Selection:</b> Based on your business requirements, we help you choose the best cloud platform (AWS, Azure, Google Cloud) or a hybrid/multi-cloud strategy that fits your technical and financial goals.</li>
                  <li><b>•	Performance Metrics & SLAs:</b> We define key performance indicators (KPIs) and service-level agreements (SLAs) to track the performance, uptime, and cost-efficiency of your cloud environment post-migration.</li>
                  <li><b>•	Continuous Improvement Plan:</b> Cloud technology evolves rapidly, so our roadmap includes a plan for continuous improvement, incorporating the latest tools, services, and techniques to keep your cloud environment cutting-edge.</li>
                </ul>
                <br/>
                <p>By delivering a detailed assessment and customized roadmap, we ensure your cloud adoption or optimization journey is efficient, secure, and aligned with your business objectives. Let’s help you accelerate your digital transformation with a clear, actionable cloud strategy!</p>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CloudConsulting;