import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styling

function Home() {
    return (
      <div>
        {/* Section 1: Scrolling Pictures */}
        <section className="rev_slidder_wrapper">
          <Carousel autoPlay infiniteLoop showThumbs={false} showArrows={false} showStatus={false}>
            <div>
              <img src="/images/slider/14.png" alt="" width="1920" height="550" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                <div className="slide-content-box">
                  <h1>Unlock the power of technology</h1>
                </div>
            </div>
            <div>
              <img src="/images/slider/2.jpg" alt="" width="1920" height="550" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                <div className="slide-content-box">
                  <h1>Reach CloudZen, Achieve IT Bliss</h1>
                </div>
            </div>
            <div>
              <img src="/images/slider/15.png" alt="" width="1920" height="550" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                <div className="slide-content-box">
                  <h1>Reaching New Heights with CloudZen Solutions</h1>
                </div>
            </div>
            <div>
              <img src="/images/slider/4.jpg" alt="" width="1920" height="550" data-bgposition="top center" data-bgfit="cover" data-bgrepeat="no-repeat" data-bgparallax="1" />
                <div className="slide-content-box">
                  <h1>Powering the Cloud, One Zen Step at a Time</h1>
                </div>
            </div>
          </Carousel>
        </section>

      {/* Section with Left Writings and Right Picture */}
      <section className="content-section">
        <div class="container">
          <div class="section-title center">
            <h2>About CloudZen</h2>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="about-info">
                <h4>Unlock the power of your business</h4>
                <div class="text">
                  <p>CloudZen Solutions is an IT services and cloud solutions provider, helping businesses unlock the potential of their enterprise with cutting-edge technology, innovation and expertise.</p>
                  
                  <p class="pt-20"> <strong>Unrivaled expertise for your business</strong></p>
                  <p>Our team of experienced professionals will provide customized solutions to help you reach your goals, leveraging global IT consulting and development services tailored to your needs.</p>

                  <p class="pt-20"><strong>Cutting-edge technology at your fingertips</strong></p>
                  <p>At CloudZen Solutions, we stay ahead of the curve when it comes to the latest trends in technology and cloud offerings. We offer cutting-edge solutions that are designed to keep you on top of the game.</p>

                  <br/>
                </div>

                <div class="link_btn">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <img src='/images/service5.avif' alt=""/>
            </div>
          </div>
        </div>
      </section>

      <section class="why-us">
        <div class="container">
          <div class="section-title center">
            <h2>Our Services</h2>
          </div>
        
          <div class="row clearfix">
            {/* Featured Service */}
            <div class="featured-service col-md-4 col-sm-6 col-xs-12">
              <div class="inner-box wow fadeIn animated" data-wow-delay="0ms" data-wow-duration="1500ms">
                <div class="image-box">
                  <figure class="image"><a href="/application-dev"><img src="/images/service9.jpg" alt=""/></a></figure>
                  <div class="caption-box">
                    <div class="icon"><span class="icon-graphic"></span></div>
                    <h4 class="title"><a href="/application-dev">Develop your applications with ease</a></h4>
                  </div>
                  {/* Overlay */}
                  <div class="overlay-box">
                    <div class="icon_box"><span class="icon-graphic"></span></div>
                    <div class="overlay-inner">
                      <div class="overlay-content">
                        <h4 class="title"><a href="/application-dev">Develop your applications with ease</a></h4>
                        <div class="text">We provide custom application development services to make sure you get exactly what you need for your business. </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              
            {/* Featured Service */}
            <div class="featured-service col-md-4 col-sm-6 col-xs-12">
              <div class="inner-box wow fadeIn animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                <div class="image-box">
                  <figure class="image"><a href="/system-integration"><img src="/images/service8.jpeg" alt=""/></a></figure>
                  <div class="caption-box">
                    <div class="icon"><span class="icon-layers"></span></div>
                    <h4 class="title"><a href="/system-integration">Integrate Your Systems Seamlessly</a></h4>
                  </div>
                  {/* Overlay */}
                  <div class="overlay-box">
                      <div class="icon_box"><span class="icon-layers"></span></div>
                      <div class="overlay-inner">
                          <div class="overlay-content">
                              <h4 class="title"><a href="/system-integration">Integrate Your Systems Seamlessly</a></h4>
                              <div class="text">Our system integration services allow you to integrate different systems into one unified platform. </div>
                          </div>
                      </div>
                  </div>
                </div>
              </div>
            </div>
              
            {/* Featured Service */}
            <div class="featured-service col-md-4 col-sm-6 col-xs-12">
              <div class="inner-box wow fadeIn animated" data-wow-delay="600ms" data-wow-duration="1500ms">
                <div class="image-box">
                  <figure class="image"><a href="/cloud-services"><img src="/images/service4.jpg" alt=""/></a></figure>
                  <div class="caption-box">
                    <div class="icon"><span class="icon-computer"></span></div>
                    <h4 class="title"><a href="/cloud-services">Access Cloud Services Securely</a></h4>
                  </div>
                  {/* Overlay */}
                  <div class="overlay-box">
                    <div class="icon_box"><span class="icon-computer"></span></div>
                    <div class="overlay-inner">
                      <div class="overlay-content">
                        <h4 class="title"><a href="/cloud-services">Access Cloud Services Securely</a></h4>
                        <div class="text">With our cloud services, you can access your data, applications, and other resources securely, no matter where you are located.  </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>            
        </div>
      </section>
    </div>
  );
}

export default Home;