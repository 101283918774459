import React from 'react';
import { Link } from 'react-router-dom';

function ApplicationDev() {
  const divStyle = {
    backgroundImage: "url('/images/background/cloud-software.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
            <h3>Applications Development</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Applications Development</li>
          </ol>
        </div>
      </nav>

      <section className="content-section">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12">
              <div class="about-info">
                <h4>Cloud Solutions for Modern Businesses</h4>
                <div className="text">
                  <p>At CloudZen Solutions, we specialize in providing cutting-edge cloud services tailored to your business needs. With our expertise, you can scale your operations, enhance efficiency, and reduce operational costs, all while maintaining security and compliance.</p>
                  
                  <p class="pt-20"> <strong>Cloud Strategy & Consulting</strong></p>
                  <p>We work with you to design and implement a cloud strategy that aligns with your business objectives. Whether you’re migrating to the cloud or optimizing your current infrastructure, we ensure a seamless transition.</p>

                  <p class="pt-20"><strong>Cloud Application Development</strong></p>
                  <p>Our team develops secure, scalable, and high-performing applications that leverage the full potential of the cloud.</p>
                </div>

                <div class="link_btn">
                </div>
              </div>
            </div>
            <div class="col-md-6 col-sm-12 col-xs-12">
              <img src='/images/service6.jpeg' alt=""/>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default ApplicationDev;